export const handlePreRequestSubtitles = async (text, sourceLanguage, targetLanguage, vendor) => {
    // https://stackoverflow.com/a/5069776
    // replace multiple strings by arrays given
    String.prototype.replaceArray = function (find, replace) {
        var replaceString = this;
        for (var i = 0; i < find.length; i++) {
            const regexString = new RegExp(`\\b${find[i]}\\b`, 'g');
            replaceString = replaceString.replace(regexString, replace[i]);
        }
        return replaceString;
    };
    let find = null;
    let replace = null;
    let newText = null;

    const dictionary = window.dictionaries.find(
        (dictionary) =>
            dictionary.sourceLanguage === sourceLanguage &&
            dictionary.targetLanguage === targetLanguage,
    );

    if (dictionary !== undefined) {
        find = dictionary.replacements.map((item) => item.from);
        replace = dictionary.replacements.map((item) => item.to);
        newText = text.replaceArray(find, replace);
    } else {
        newText = text;
    }

    return {
        text: newText,
        sourceLanguage,
        targetLanguage,
        vendor,
    };
};
