const specialLanguages = [
    'amharic',
    'albanian',
    'japanese',
    'gujarati',
    'arabic',
    'chinese',
    'hebrew',
    'greek',
    'hindi',
    'tamil',
    'georgian',
];

export default specialLanguages;
