function buildSubtitlesFile(subtitles) {
    let content = [];
    subtitles.forEach((item, index) => {
        if (index === 0 || item.name !== subtitles[index - 1].name) {
            content.push({
                text: item.name,
                style: 'header',
                bold: true,
            });
        } else {
            content.push({
                text: '',
                style: 'header',
                bold: true,
            });
        }

        if (item.bilingualText) {
            content.push({
                ul: [
                    {
                        text: `(${item.speakerLanguage}): ${item.bilingualText}`,
                        italics: true,
                        color: '#555',
                    },
                    { text: `(${item.speakerLanguage}): ${item.text}`, bold: false },
                ],
            });
        } else {
            content.push({
                ul: [{ text: `(${item.speakerLanguage}): ${item.text}`, bold: false }],
            });
        }
    });
    return content;
}

export default buildSubtitlesFile;
