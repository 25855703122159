const endpointTranslate = {
    method: 'POST',
    url: 'https://api.worldwidetechconnections.com/services/ttt/:sourceLanguage/:targetLanguage',
    valueResponse: 'translated_text',
};

const endpointTextToSpeech = {
    method: 'POST',
    url: 'https://api.worldwidetechconnections.com/services/tts/:sourceLanguage/:sourceLanguage',
};

const endpointSpeechToText = {
    method: 'POST',
    url: 'https://api.worldwidetechconnections.com/services/stt/:sourceLanguage/:sourceLanguage',
    valueResponse: 'source_text',
    isFormData: false,
};

function signedEndpoint(apiToken, endpoint) {
    const cookieRegex = document.cookie.match(new RegExp(`(?:^|;\\s*)anonymous-id=([^;]*)`));
    const apiAnonymousId = cookieRegex ? '&apiAnonymousID=' + cookieRegex[1] : '';

    let operator = '?';
    if (endpoint.url.includes(operator)) {
        operator = '&';
    }

    return {
        method: endpoint.method,
        url: endpoint.url + operator + 'apiAuthorization=' + apiToken + apiAnonymousId,
        valueResponse: endpoint.valueResponse,
        isFormData: endpoint.isFormData,
    };
}

export { endpointSpeechToText, endpointTextToSpeech, endpointTranslate, signedEndpoint };
